import React from "react";
import styles from "./about.module.css";

// Components
import HeadingOne from "../components/HeadingOne";
import ParagraphOne from "../components/ParagraphOne";
import CustomCarousel from "../components/CustomCarousel";

// Images and Videos
import AboutImageOne from "../assets/images/about/family_potrait.jpeg";
import AboutImageTwo from "../assets/images/about/group_photo 3.jpg";
import AboutImageThree from "../assets/images/about/fam_3.JPG";
import AboutImageFour from "../assets/images/about/fam_2.JPG";

function About() {
    return ( 
        <div className={styles.aboutPage}>
            {/* Text Section (Left) */}
            <div className={styles.aboutTextSection}>
                <HeadingOne>Welcome to Senoritas</HeadingOne>
                <ParagraphOne>
                    At the heart of our restaurant lies a rich family legacy that spans generations. Our journey began in the busy kitchen of my grandmother's restaurant, where I found my passion for culinary artistry at the young age of four. Growing up alongside my grandmother, who has honed her craft for over six decades, instilled in me a deep appreciation for the culinary world.
                </ParagraphOne>

                <ParagraphOne>
                    From childhood games of serving imaginary dishes to eagerly assisting in the kitchen, my grandmother imparted invaluable lessons of dedication and love for the culinary arts. Her guidance fueled my desire to pursue this path, and it is with immense gratitude that we pay homage to her legacy through our restaurant.
                </ParagraphOne>

                <ParagraphOne>
                    Located in the vibrant community of  Shelby Twp, Michigan, our restaurant serves as a tribute to the traditions and flavors of authentic Mexican cuisine. Drawing inspiration from my grandmother's signature dishes, such as the crispy potato tacos and succulent pork ribs in guajillo sauce, we strive to preserve the essence of our culinary heritage.
                </ParagraphOne>

                <ParagraphOne>
                    Despite our growth and success, our commitment to authenticity remains unwavering. We understand that our food may not appeal to everyone's taste, but we firmly believe in staying true to the roots of Mexican gastronomy. Whether you're a longtime patron or a first-time visitor, we invite you to savor the flavors of Mexico with us.
                </ParagraphOne>

                <ParagraphOne>
                    In May 2022, we embarked on a new chapter as the proud owners of this beloved establishment. With our recent relocation on Feb 9, 2024, we are excited to continue sharing our passion for authentic Mexican cuisine with our community and beyond.
                </ParagraphOne>
            </div>

            {/* Image section (Right) */}
            <div className={styles.aboutImageSection}>
                <CustomCarousel>
                    <div className={styles.aboutImageContainer}>
                        <img className={styles.aboutImage} src={AboutImageTwo} alt='Owning family potrait'/>
                    </div>

                    <div className={styles.aboutImageContainer}>
                        <img className={styles.aboutImage} src={AboutImageThree} alt='Owning family potrait'/>
                    </div>

                    <div className={styles.aboutImageContainer}>
                        <img className={styles.aboutImage} src={AboutImageFour} alt='Owning family potrait'/>
                    </div>

                    <div className={styles.aboutImageContainer}>
                        <img className={styles.aboutImage} src={AboutImageOne} alt='Owning family potrait'/>
                    </div>
                </CustomCarousel>
            </div>
        </div>
     );
}

export default About;
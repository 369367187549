import React from 'react';
import styles from './NavBarMobile.module.css';
import { NavLink } from "react-router-dom";

// Image imports
import Logo from '../../assets/images/logo_transparent.png';
import Hamburger from '../../assets/images/icons/white_hamburger_icon.png';

function NavBarMobile({children}) {
    // Opens and closes the navbar when the hamburger icon is pressed
    const onHamburgerClick = () => {
        var linkContainer = document.getElementById('links');
        
        if (linkContainer.style.display === "block") {
          linkContainer.style.display = "none";
        } else {
          linkContainer.style.display = "block";
        }
    }

    return ( 
        <div>
            <div className={styles.topnav}>
                {/* -----  Logo on the left side of the navigation bar -----  */}
                <NavLink to="/" style={{ display: 'inline-block'}} className="navLink">
                    <img className={styles.navbarLogo} src={Logo}  alt="Restaurant logo"/>
                </NavLink>

                {/* -----  Navbar options -----  */}
                <div className={styles.myLinks} id='links'>
                    { children }
                </div>

                {/* -----  Navbar hamburger icon -----  */}
                <img className={styles.hamburgerIcon} src={Hamburger} onClick={onHamburgerClick}  alt="Hamburger Icon"/>
            </div>
        </div>
     );
}

export default NavBarMobile;
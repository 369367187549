import React, { useState } from "react";
import styles from './catering.module.css';

// Components
import HeadingOne from '../components/HeadingOne';
import HeadingTwo from "../components/HeadingTwo";
import BaseButton from "../components/BaseButton";
import ParagraphOne from "../components/ParagraphOne";

// Images
import TacoBarImage from "../assets/images/catering-1.jpeg";

// Constants
const TACOS_PER_POUND = 6;
const CHIP_TRAYS = [['bag', 2], ['small', 9], ['medium', 13], ['large', 26]];
const SALSA_GUAC_CUPS = [['8oz cup', 2], ['16oz cup', 4], ['32oz cup', 8]];
const RICE_BEAN_TRAYS = [['side', 1], ['small', 9], ['medium', 13], ['large', 26]];


function counter(options, totalAmount) {
    // Sort the options array in descending order to start with the largest denomination
    options.sort((a, b) => b[1] - a[1]);
  
    let result = {};
    let remainingAmount = totalAmount;
  
    // Iterate over each denomination
    options.forEach(option => {
      // Calculate how many coins of this option are needed
      const count = Math.floor(remainingAmount / option[1]);
      // Only add to result if count is non-zero
      if (count > 0) {
        result[option[0]] = count;
      }

      // Update the remainingAmount with the remainder
      remainingAmount -= count * option[1];
    });
  
    return result;
}
  

function Catering() {
    const [people, setPeople] = useState(0);
    const [tacos, setTacos] = useState(1);

    const [poundsMeat, setPoundsMeat] = useState(0);
    const [chipTrays, setChipTrays] = useState("");
    const [riceBeanTrays, setRiceBeanTray] = useState("");
    const [salsaGuacCups, setSalsaGuacCups] = useState("");

    const handleOrderNowClick = () => {
        window.location.href = 'https://senoritasmexicanfood.smartonlineorder.com/';
    }

    function handlePeopleChange(e) {
        const numPeople = e.target.value;
        setPeople(numPeople);
        calculateCatering(numPeople, tacos);
    }

    function handleTacosChange(e) {
        const numTacos = e.target.value;
        setTacos(numTacos);
        calculateCatering(people, numTacos);
    }

    function calculateCatering(peopleAmount, tacosAmount) {
        // Calculate how many pounds of meat
        const numTacos = peopleAmount*tacosAmount;

        // const numPounds = numTacos/TACOS_PER_POUND;
        const numPounds = Math.round((numTacos/TACOS_PER_POUND) * 100) / 100;
        setPoundsMeat(numPounds);

        // Calculate how many trays of chips
        const numChipTrays = counter(CHIP_TRAYS, peopleAmount);
        var numChipTrayString = '';
        for (let key in numChipTrays) {
            numChipTrayString = numChipTrayString + `  ${numChipTrays[key]} ${key},`;
        }
        numChipTrayString = numChipTrayString.slice(0, -1);
        setChipTrays(numChipTrayString);

        // Calculate how many trays of rice and/or beans
        const numSalsaGuacCups = counter(SALSA_GUAC_CUPS, peopleAmount);
        var numSalsaGuacCupsString = '';
        for (let key in numSalsaGuacCups) {
            numSalsaGuacCupsString = numSalsaGuacCupsString + `  ${numSalsaGuacCups[key]} ${key},`;
        }
        numSalsaGuacCupsString = numSalsaGuacCupsString.slice(0, -1);
        setSalsaGuacCups(numSalsaGuacCupsString);

        // Calculate how many trays of rice and/or beans
        const numRiceBeanTrays = counter(RICE_BEAN_TRAYS, peopleAmount);
        var numRiceBeanTraysString = '';
        for (let key in numRiceBeanTrays) {
            numRiceBeanTraysString = numRiceBeanTraysString + `  ${numRiceBeanTrays[key]} ${key},`;
        }
        numRiceBeanTraysString = numRiceBeanTraysString.slice(0, -1);
        setRiceBeanTray(numRiceBeanTraysString);
    }
    
    function handleSubmit(e) {
        e.preventDefault();
        alert('Calculating amount of food required for your party...')
    }

    return ( 
        <div className={styles.cateringPage}>
            <div className={styles.floatingCard}>
                <HeadingOne>Catering Calculator</HeadingOne>
                <div className={styles.calculatorContent}>
                    {/* ----- Calculator Section ----- */}
                    <div className={styles.calculatorSection}>
                        <form className={styles.calculatorForm} onSubmit={handleSubmit}>
                            <label htmlFor="people">How many people are in your party?</label>
                            <input type="number" name="people" id="people" onChange={handlePeopleChange} min={0}></input>

                            <br></br>

                            <label htmlFor="tacos">How many tacos per person?</label>
                            <select name="tacos" id="tacos" onChange={handleTacosChange}>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                            </select>

                            <br></br>
                        </form>
                        <div>
                            <HeadingTwo>Food required for your party:</HeadingTwo>
                            <ParagraphOne><strong>Pounds of Meat:</strong> {poundsMeat}</ParagraphOne>
                            <ParagraphOne><strong>Trays of Chips:</strong> {chipTrays}</ParagraphOne>
                            <ParagraphOne><strong>Ounces of Salsa or Guacamole:</strong> {salsaGuacCups}</ParagraphOne>
                            <ParagraphOne><strong>Trays of Rice or Beans:</strong> {riceBeanTrays}</ParagraphOne>
                        </div>
                    </div>

                    {/* ----- Taco Bar Image Section ----- */}
                    <div className={styles.mediaSection}>
                        <img className={styles.tacoBarImage} src={TacoBarImage} alt='Taco bar'/>
                    </div>
                </div>

                <ParagraphOne>For questions or specific orders, contact us at +1 (586) 251-2142.</ParagraphOne>
                <BaseButton additionalClassNames={styles.cateringButton} text='Order Now' color={process.env.REACT_APP_RED} onClick={handleOrderNowClick}/>
            </div>
        </div>
     );
}

export default Catering;
import React from 'react';
import styles from './shop.module.css';

import ConstructionSite from '../components/ConstructionSite';

function Shop() {
    return ( 
        <ConstructionSite />
     );
}

export default Shop;
import React from 'react';
import styles from './menu.module.css';

import ConstructionSite from '../components/ConstructionSite';

// Images
import MenuOne from '../assets/images/menu/8-24-menu-1.png';
import MenuTwo from '../assets/images/menu/8-24-menu-2.png';

function Menu() {
    return ( 
        <div className={styles.menuPage}>
            <img className={styles.menuImage} src={MenuOne} alt='Menu first page'/>
            <img className={styles.menuImage} src={MenuTwo} alt='Menu first page'/>
        </div>
     );
}

export default Menu;